require("./toolbox/ToolboxModel");

require("./toolbox/ToolboxView");

require("./toolbox/feature/SaveAsImage");

require("./toolbox/feature/MagicType");

require("./toolbox/feature/DataView");

require("./toolbox/feature/DataZoom");

require("./toolbox/feature/Restore");