import { ESCAPE } from '@angular/cdk/keycodes';
import { Subject, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
var OwlDialogRef = (function () {
    function OwlDialogRef(overlayRef, container, id, location) {
        var _this = this;
        this.overlayRef = overlayRef;
        this.container = container;
        this.id = id;
        this._beforeClose$ = new Subject();
        this._afterOpen$ = new Subject();
        this._afterClosed$ = new Subject();
        this.locationChanged = Subscription.EMPTY;
        this.disableClose = this.container.config.disableClose;
        this.container.animationStateChanged
            .pipe(filter(function (event) { return event.phaseName === 'done' && event.toState === 'enter'; }), take(1))
            .subscribe(function () {
            _this._afterOpen$.next();
            _this._afterOpen$.complete();
        });
        this.container.animationStateChanged
            .pipe(filter(function (event) { return event.phaseName === 'done' && event.toState === 'exit'; }), take(1))
            .subscribe(function () {
            _this.overlayRef.dispose();
            _this.locationChanged.unsubscribe();
            _this._afterClosed$.next(_this.result);
            _this._afterClosed$.complete();
            _this.componentInstance = null;
        });
        this.overlayRef.keydownEvents()
            .pipe(filter(function (event) { return event.keyCode === ESCAPE && !_this.disableClose; }))
            .subscribe(function () { return _this.close(); });
        if (location) {
            this.locationChanged = location.subscribe(function () {
                if (_this.container.config.closeOnNavigation) {
                    _this.close();
                }
            });
        }
    }
    OwlDialogRef.prototype.close = function (dialogResult) {
        var _this = this;
        this.result = dialogResult;
        this.container.animationStateChanged
            .pipe(filter(function (event) { return event.phaseName === 'start'; }), take(1))
            .subscribe(function () {
            _this._beforeClose$.next(dialogResult);
            _this._beforeClose$.complete();
            _this.overlayRef.detachBackdrop();
        });
        this.container.startExitAnimation();
    };
    OwlDialogRef.prototype.backdropClick = function () {
        return this.overlayRef.backdropClick();
    };
    OwlDialogRef.prototype.keydownEvents = function () {
        return this.overlayRef.keydownEvents();
    };
    OwlDialogRef.prototype.updatePosition = function (position) {
        var strategy = this.getPositionStrategy();
        if (position && (position.left || position.right)) {
            position.left ? strategy.left(position.left) : strategy.right(position.right);
        }
        else {
            strategy.centerHorizontally();
        }
        if (position && (position.top || position.bottom)) {
            position.top ? strategy.top(position.top) : strategy.bottom(position.bottom);
        }
        else {
            strategy.centerVertically();
        }
        this.overlayRef.updatePosition();
        return this;
    };
    OwlDialogRef.prototype.updateSize = function (width, height) {
        if (width === void 0) { width = 'auto'; }
        if (height === void 0) { height = 'auto'; }
        this.getPositionStrategy().width(width).height(height);
        this.overlayRef.updatePosition();
        return this;
    };
    OwlDialogRef.prototype.isAnimating = function () {
        return this.container.isAnimating;
    };
    OwlDialogRef.prototype.afterOpen = function () {
        return this._afterOpen$.asObservable();
    };
    OwlDialogRef.prototype.beforeClose = function () {
        return this._beforeClose$.asObservable();
    };
    OwlDialogRef.prototype.afterClosed = function () {
        return this._afterClosed$.asObservable();
    };
    OwlDialogRef.prototype.getPositionStrategy = function () {
        return this.overlayRef.getConfig().positionStrategy;
    };
    return OwlDialogRef;
}());
export { OwlDialogRef };
